import styled from 'styled-components';

export const VideoPreview = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  background-color: black;
  border-radius: 8px;
  z-index: 0;
  overflow: hidden;

  p {
    color: #fff;
  }

  @media ${(props) => props.theme.responsive.phone} {
    padding-top: 100%;
  }
`;

export const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 8px;
`;

export const VideoEmptyMessage = styled.p`
  color: #fff;
  text-align: center;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;
