import React from 'react';
import {Button} from '../Buttons';
import {HeadingS, Text} from '../Typography';
import {Card, Center} from './Boxes/layout';
import requestPermissions from '../../assets/images/requestPermissions.gif';
import styled from 'styled-components';

interface RequestPermissionsProps {
  setPermissions: () => void;
  missingPermissions: 'mic' | 'cam' | 'both';
}

const RequestPermissions = ({
  setPermissions,
  missingPermissions,
}: RequestPermissionsProps) => {
  let permissionsText =
    'Per permettere agli altri di vederti e sentirti, il browser richiederà il permesso di usare la tua webcam e microfono.';
  let buttonTitle = 'Richiedi permessi';

  if (missingPermissions === 'mic') {
    permissionsText =
      'Per permettere agli altri di sentirti, il browser richiederà il permesso di usare il tuo microfono.';
    buttonTitle = 'Richiedi permessi microfono';
  }
  if (missingPermissions === 'cam') {
    permissionsText =
      'Per permettere agli altri di vederti, il browser richiederà il permesso di usare la tua webcam.';
    buttonTitle = 'Richiedi permessi webcam';
  }

  return (
    <Card>
      <Center>
        <Img src={requestPermissions.src} alt={buttonTitle} />
        <HeadingS>{permissionsText}</HeadingS>
        <Text>Potrai disattivarli in qualsiasi momento.</Text>
        <Button title={buttonTitle} onClick={() => setPermissions()} />
      </Center>
    </Card>
  );
};

export default RequestPermissions;

const Img = styled.img`
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: ${(props) => props.theme.layout.frontendSpace};
`;
