import React from 'react';

export const IconSelected = (props: IconProps) => {
  const size = props.size || 24;
  const color = props.fill || 'black';
  const stroke = props.stroke || '1.5';
  return (
    <svg
      version="1.1"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path d="M0 0h24v24h-24Z"></path>
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={stroke}
          d="M20 6.5l-11 11 -5-5"
        ></path>
      </g>
    </svg>
  );
};
