import styled, {css} from 'styled-components';

export const FlowWrapper = styled.div<{$visible?: boolean}>`
  transition: all ease ${(props) => props.theme.timing.default};
  transform: translateY(20px);
  visibility: hidden;
  opacity: 0;
  height: 0;

  ${(props) =>
    props.$visible &&
    css`
      visibility: visible;
      height: auto;
      transform: translateY(0);
      opacity: 1;
    `}
`;

export const Card = styled.div`
  margin-top: ${(props) => props.theme.layout.frontendSpace};
  padding: 32px;
  border-radius: 5px;
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0 0 26px ${(props) => props.theme.colors.shadow};

  a {
    color: ${(props) => props.theme.colors.main};
  }
`;

export const Center = styled.div<{$color?: string}>`
  display: block;
  width: 100%;
  text-align: center;
  margin: 30px auto;
`;
