import React from 'react';
import styled from 'styled-components';
import {HeadingM, TextS} from '../Typography';
import {Button} from '../Buttons';

interface PermissionsHowToProps {
  type: 'mic' | 'cam' | 'both';
}

const PermissionsHowTo = ({type}: PermissionsHowToProps) => {
  let device = 'del microfono';

  if (type === 'cam') {
    device = 'della webcam';
  }

  if (type === 'both') {
    device = 'della webcam e del microfono';
  }

  return (
    <Container>
      <HeadingM>
        I permessi per webcam e microfono non sono abilitati, ecco come fare:
      </HeadingM>
      <TextS>
        Nella barra URL del browser, clicca sull'icona per gestire i permessi e
        autorizza l'utilizzo {device}
      </TextS>
      <Video playsInline autoPlay loop muted>
        <source src="/assets/unlockPermissions.mp4" type="video/mp4" />
      </Video>
      <TextS>
        Una volta fatto,{' '}
        <Button
          title="ricarca la pagina"
          onClick={() => window?.location.reload()}
          inline
          small
        />
      </TextS>
    </Container>
  );
};

export default PermissionsHowTo;

const Container = styled.div``;

const Video = styled.video`
  width: 100%;
  height: auto;
  margin: 16px auto;
`;
