import React from 'react';
import styled from 'styled-components';
import ParticipantMedia from './ParticipantMedia';
import {VideoPreview, VideoWrapper, VideoEmptyMessage} from './VideoPreview';
import VideoControls from './VideoControls';
import {
  MediaStreamVideoTrackWithTwilio,
  VideoTrackSpecs,
} from 'youmain-videoroom-lib';

interface LocalMediaProps {
  videoTrack: MediaStreamVideoTrackWithTwilio | null;
  audioTrack: MediaStreamTrack | null;
  videoSpecs: VideoTrackSpecs | null;

  videoDevices: MediaDeviceInfo[];
  audioDevices: MediaDeviceInfo[];

  selectedVideoDevice?: string;
  selectedAudioDevice?: string;

  openMic: (deviceId?: string) => Promise<any>;
  closeMic: () => Promise<void>;
  openCamera: (deviceId?: string) => Promise<any>;
  closeCamera: () => Promise<void>;
  toggleBlur?: () => void;
  blurEnabled?: boolean;
}

export default function LocalMediaSetup({
  videoTrack,
  audioTrack,
  videoDevices,
  audioDevices,
  videoSpecs,
  selectedVideoDevice,
  selectedAudioDevice,
  openMic,
  closeMic,
  openCamera,
  closeCamera,
  toggleBlur,
  blurEnabled,
}: LocalMediaProps) {
  return (
    <>
      <VideoPreview>
        {!videoTrack && (
          <VideoEmptyMessage>Camera disattivata</VideoEmptyMessage>
        )}
        <VideoWrapper>
          <StyledParticipantMedia
            videoTrack={videoTrack}
            videoTwilio={videoTrack?.twilioTrack}
            audioTrack={audioTrack}
            isMobileVideo={
              videoSpecs && videoSpecs.aspectRatio < 1 ? true : false
            }
          />
        </VideoWrapper>

        <ControlsContainer>
          <VideoControls
            enabledCamera={!!videoTrack}
            enabledMic={!!audioTrack}
            videoDevices={videoDevices}
            audioDevices={audioDevices}
            openMic={openMic}
            closeMic={closeMic}
            openCamera={openCamera}
            closeCamera={closeCamera}
            selectedAudioDevice={selectedAudioDevice}
            selectedVideoDevice={selectedVideoDevice}
            toggleBlur={toggleBlur}
            blurEnabled={!!blurEnabled}
          />
        </ControlsContainer>
      </VideoPreview>
    </>
  );
}

const StyledParticipantMedia = styled(ParticipantMedia)<{
  isMobileVideo: boolean;
}>`
  width: 100%;
  height: 100%;

  video {
    width: 100%;
    height: 100%;
    object-fit: ${(props) => (props.isMobileVideo ? 'contain' : 'cover')};
  }
`;

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
