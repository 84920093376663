import React from 'react';
import {ButtonControl} from '../Buttons';
import {IconMic, IconMicOff, IconCam, IconCamOff} from '../Icons';
import {ContextualMenu, ContextualMenuItem} from '../ContextualMenu';
import {useLocalTracks} from 'youmain-videoroom-lib';

type VideoControlsProps = {
  enabledCamera: boolean;
  enabledMic: boolean;
  videoDevices: MediaDeviceInfo[];
  audioDevices: MediaDeviceInfo[];
  selectedVideoDevice?: string;
  selectedAudioDevice?: string;
  openMic: (deviceId?: string) => Promise<any>;
  closeMic: () => Promise<void>;
  openCamera: (deviceId?: string) => Promise<any>;
  closeCamera: () => Promise<void>;
  toggleBlur: (() => void) | undefined;
  blurEnabled: boolean;
  showShortcutsTooltip?: boolean;
};

const VideoControls = ({
  enabledCamera,
  enabledMic,
  videoDevices,
  audioDevices,
  selectedVideoDevice,
  selectedAudioDevice,
  openMic,
  closeMic,
  openCamera,
  closeCamera,
  toggleBlur,
  blurEnabled,
  showShortcutsTooltip,
}: VideoControlsProps) => {
  const {isBlurSupported} = useLocalTracks();

  return (
    <>
      <ButtonControl
        icon={enabledMic ? IconMic : IconMicOff}
        onClick={enabledMic ? () => closeMic() : () => openMic()}
        invert={!enabledMic}
      >
        <ContextualMenu>
          {audioDevices.length > 0 && (
            <ContextualMenuItem title="Seleziona microfono" icon={IconMic}>
              {audioDevices.map((device) => (
                <ContextualMenuItem
                  key={device.deviceId}
                  title={device.label}
                  selected={selectedAudioDevice === device.label}
                  onClick={() => openMic(device.deviceId)}
                />
              ))}
            </ContextualMenuItem>
          )}
          <ContextualMenuItem
            textRed
            title={
              enabledMic
                ? `Muta microfono${!!showShortcutsTooltip ? ' (m)' : ''}`
                : `Attiva microfono${!!showShortcutsTooltip ? ' (m)' : ''}`
            }
            onClick={enabledMic ? closeMic : openMic}
          />
        </ContextualMenu>
      </ButtonControl>
      <ButtonControl
        icon={enabledCamera ? IconCam : IconCamOff}
        invert={!enabledCamera}
        onClick={enabledCamera ? closeCamera : openCamera}
      >
        <ContextualMenu>
          {videoDevices.length > 0 && (
            <>
              <ContextualMenuItem title="Seleziona camera" icon={IconCam}>
                {videoDevices.map((device) => (
                  <ContextualMenuItem
                    key={device.deviceId}
                    title={device.label}
                    selected={selectedVideoDevice === device.label}
                    onClick={() => openCamera(device.deviceId)}
                  />
                ))}
              </ContextualMenuItem>
              {isBlurSupported && toggleBlur && (
                <ContextualMenuItem
                  title="Sfocatura sfondo"
                  onClick={toggleBlur}
                  selected={blurEnabled}
                />
              )}
            </>
          )}
          <ContextualMenuItem
            textRed
            title={
              enabledCamera
                ? `Disattiva camera${!!showShortcutsTooltip ? ' (v)' : ''}`
                : `Attiva camera${!!showShortcutsTooltip ? ' (v)' : ''}`
            }
            onClick={enabledCamera ? closeCamera : openCamera}
          />
        </ContextualMenu>
      </ButtonControl>
    </>
  );
};

export default VideoControls;
