import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';

type SubContextualMenuProps = PropsWithChildren<{}>;

const SubContextualMenu = ({children}: SubContextualMenuProps) => {
  return <Container>{children}</Container>;
};

export default SubContextualMenu;

const Container = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 100%;
  padding-left: 4px;
  border-radius: 4px;
  box-shadow: 0 0 10px ${(props) => props.theme.colors.shadow};

  div:hover > &,
  div:active > & {
    visibility: visible;
    opacity: 1;
  }
`;
