import React, {useRef, useEffect} from 'react';
import {MediaStreamVideoTrackWithTwilio} from 'youmain-videoroom-lib';

interface ParticipantMediaProps {
  className?: string;
  videoTrack: MediaStreamVideoTrackWithTwilio | null;
  audioTrack: MediaStreamTrack | null;
  videoTwilio?: MediaStreamVideoTrackWithTwilio['twilioTrack'];
}

export default function ParticipantMedia(props: ParticipantMediaProps) {
  const container = useRef<HTMLDivElement>(null);
  const video = useRef<HTMLVideoElement>(null);
  const audio = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    const videoRef = video.current;
    if (!!props.videoTrack && videoRef) {
      const videoStream = new MediaStream();
      videoStream.addTrack(props.videoTrack);

      videoRef.srcObject = videoStream;
    }
  }, [props.videoTrack]);

  useEffect(() => {
    const audioRef = audio.current;
    if (!!props.audioTrack && audioRef) {
      const audioStream = new MediaStream();
      audioStream.addTrack(props.audioTrack);

      audioRef.srcObject = audioStream;
    }
  }, [props.audioTrack]);

  useEffect(() => {
    if (!props.videoTwilio) {
      return;
    }
    props.videoTwilio.attach(video.current);
  }, [props.videoTwilio]);

  return (
    <div className={props.className} ref={container}>
      {props.videoTrack && <video ref={video} muted playsInline autoPlay />}
      <audio ref={audio} />
    </div>
  );
}
