import React, {useContext} from 'react';
import styled, {ThemeContext} from 'styled-components';
import {IconBrowser, IconMore} from '../Icons';
import {HeadingS, Text, TextS} from '../Typography';
import isInAppBrowser from '../../utils/isInAppBrowser';

export default function BrowserSupport() {
  const theme = useContext(ThemeContext);
  const isInApp = isInAppBrowser();

  return (
    <Container>
      <HeadingS>C’è un problema! Proviamo a risolverlo.</HeadingS>
      <Center>
        <IconBrowser fill={theme.colors.warning} size="100px" />
        {isInApp ? (
          <Text>Per accedere al servizio, usa il browser nativo</Text>
        ) : (
          <Text>
            Il browser che stai usando non supporta le funzionalità richieste
          </Text>
        )}
      </Center>

      {isInApp ? (
        <>
          <TextS>
            <strong>Per farlo:</strong>
          </TextS>
          <TextS style={{display: 'flex', alignItems: 'center'}}>
            <strong style={{marginRight: '4px'}}>1)</strong> Clicca sul tasto{' '}
            <CustomButton>
              <IconMore />
            </CustomButton>{' '}
            in alto a destra
          </TextS>
          <TextS>
            <strong style={{marginRight: '4px'}}>2)</strong>Seleziona "Apri nel
            browser"
          </TextS>
        </>
      ) : (
        <Center>
          <TextS>
            Per poter usufruire del servizio ti consigliamo di utilizzare i
            seguenti browser: Microsoft&nbsp;Edge, Google&nbsp;Chrome,
            Mozilla&nbsp;Firefox o Safari.
          </TextS>
        </Center>
      )}
    </Container>
  );
}

const Container = styled.div`
  font-family: ${(props) => props.theme.typography.main};
  position: relative;
  width: 100%;
  display: block;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: ${(props) => props.theme.layout.frontendSpace};
  background-color: #fff;
  box-shadow: 0px 0px 10px ${(props) => props.theme.colors.shadow};
  padding: 32px;

  h4 {
    font-size: 18px;
    margin: 0 0 5px;
  }

  ${Text} {
    color: ${(props) => props.theme.colors.warning};
    font-weight: 700;
  }
`;

const Center = styled.div`
  text-align: center;
  margin-bottom: 24px;
  padding-top: 30px;

  &:last-child {
    margin-bottom: 0;
    padding-top: 0;
  }
`;

const CustomButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  height: 28px;
  border: 1px solid ${(props) => props.theme.colors.neutral};
  border-radius: 4px;
  margin: 0 6px;

  circle {
    fill: ${(props) => props.theme.colors.label};
  }
`;
